<template>
  <div class="app-container payment-record-component">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
      </span>
      <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show" :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
          ">
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
      </finalTable>
    </div>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  queryRoomListByParam,
  editHomeStatus,
  getStoreList,
} from "@/api/ruge/gsPark/customerService/propertySales";
import { dateFormat } from "@/filters/index";
export default {
  name: "BMSparkPropertySales",
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "storeName", label: "所属项目", width: "" },
          { prop: "buildingName", label: "楼栋", width: "" },
          { prop: "floorName", label: "楼层", width: "" },
          { prop: "roomName", label: "房间号", width: "180" },
          { prop: "status", label: "房间信息", width: "200" },
          { prop: "houseStatus", label: "招商状态", width: "200" },
          { prop: "operation", label: "操作", width: "300" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          storeName: {
            type: "selector",
            label: "所属项目",
            value: "",
            placeholder: "请选择所属项目",
            actionType: "goSearch",
            optionList: [],
          },
          buildingName: {
            type: "input",
            label: "楼栋",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入楼栋",
            prefixIcon: "el-icon-search",
          },
          floorName: {
            type: "input",
            label: "楼层",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入楼层",
            prefixIcon: "el-icon-search",
          },
          roomName: {
            type: "input",
            label: "房间号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入房间号",
            prefixIcon: "el-icon-search",
          },
          status: {
            type: "selector",
            label: "房间信息",
            value: "",
            placeholder: "请选择房间信息",
            actionType: "goSearch",
            optionList: [
              {
                value: "空置",
                label: "空置",
              },
              {
                value: "已出租",
                label: "已出租",
              },
              {
                value: "已预订",
                label: "已预订",
              },
              {
                value: "预定过期",
                label: "预定过期",
              },
              {
                value: "自用",
                label: "自用",
              },
              {
                value: "未放租",
                label: "未放租",
              },
              {
                value: "预抵",
                label: "预抵",
              },
            ],
          },
          houseStatus: {
            type: "selector",
            label: "招商状态",
            value: "",
            placeholder: "请选择招商状态",
            actionType: "goSearch",
            optionList: [
              {
                value: "0",
                label: "空置",
              },
              {
                value: "1",
                label: "已租",
              },
              {
                value: "2",
                label: "在谈",
              },
              {
                value: "3",
                label: "预定 ",
              },
              {
                value: "4",
                label: "可租",
              },
            ],
          },

          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            justify: "left",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "talkAbout",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "设为在谈",
                hideProp: "isTalk",
                hideValue: false,
              },
              {
                actionType: "iconClick",
                eventName: "rentable",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "设为可租",
                hideProp: "isRentable",
                hideValue: false,
              },
              {
                actionType: "iconClick",
                eventName: "reserve",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "设为预定",
                hideProp: "isReserve",
                hideValue: false,
              },
              {
                actionType: "iconClick",
                eventName: "noTalkAbout",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "取消在谈",
                hideProp: "isnoTalkAbout",
                hideValue: false,
              },
              {
                actionType: "iconClick",
                eventName: "noReserve",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "取消预定",
                hideProp: "noFlagRes",
                hideValue: false,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          storeName: {
            inline: true,
            value: "",
          },
          buildingName: {
            inline: true,
            value: "",
          },
          floorName: {
            inline: true,
            value: "",
          },
          roomName: {
            inline: true,
            value: "",
          },
          status: {
            inline: true,
            value: "",
          },
          houseStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        storeId: null,
        buildingName: null,
        floorName: null,
        roomName: null,
        status: null,
        houseStatus: null,
      },
      loadingFlag: false,
      tbSearchObj: {},
      tbPage: {}
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTabStoreList();
    if (this.$route.query.goBackFlag) {
      this.tbSearchObj = JSON.parse(localStorage.getItem("tbPropertySale"));

      this.tbPage = JSON.parse(localStorage.getItem("tbPropertyPage"));

      Object.keys(this.tbSearchObj).map((key) => {
        //匹配之前列表查询参数，数据回显
        this.dataset.searchLineConfig[key].value = this.tbSearchObj[key];
      });
      if (this.tbPage) {
        this.requestParams.current = this.tbPage.current;
        this.dataset.pageVO.current = this.tbPage.current;
      }

      this.tableEventHandler({ params: this.tbSearchObj, type: "back" });
    } else {
      this.getTableList();
      localStorage.removeItem("tbPropertySale"); //如不是详情返回列表，删除回显查询参数记录
      localStorage.removeItem("tbPropertyPage"); //如不是详情返回列表，删除回显查询参数记录
    }
  },
  methods: {
    tbRowDetail(type, roomId) {
      let params = {
        path: "/gsPark/customerRelaManagement/propertySales/detail",
        query: { roomId: roomId, viewStatus: type },
      };
      this.$router.push(params);

      localStorage.setItem("tbPropertySale", JSON.stringify(this.tbSearchObj));
      localStorage.setItem("tbPropertyPage", JSON.stringify(this.tbPage));
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.tbSearchObj = { ...this.tbSearchObj, ...datas.params }; //记录查询条件参数
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.tbPage.current = 1  //同步修改存储值
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.findStoreId(this.requestParams);
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.tbPage = {
          current: datas.params.current.page,
          rowCount: this.requestParams.rowCount
        }; //记录查询条件参数

        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.tbRowDetail("view", datas.row.roomId);
            break;
          case "edit": //编辑
            this.tbRowDetail("edit", datas.row.roomId);
            break;
          case "talkAbout": //设为在谈
            this.changeStatus("talkAbout", datas.row);
            break;
          case "reserve": //设为预定
            this.changeStatus("reserve", datas.row);
            break;
          case "noTalkAbout": //取消在谈
            this.changeStatus("noTalkAbout", datas.row);
            break;
          case "noReserve": //取消预定
            this.changeStatus("noReserve", datas.row);
            break;
          case "rentable": //设为可租
            this.changeStatus("rentable", datas.row);
            break;
        }
      } else if (datas.type === "back") { //详情页返回列表独立事件
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.findStoreId(this.requestParams);
        this.getTableList();
      }
    },
    findStoreId(data) {
      if (data.storeName) {
        this.requestParams.storeId = data.storeName;
        delete data.storeName;
      } else if (data.storeName == "") {
        this.requestParams.storeId = data.storeName;
        delete data.storeName;
      }
    },
    changeStatus(type, row) {
      let conText = null;
      let paramStatus = null;
      if (type == "talkAbout") {
        conText = "设为在谈";
        paramStatus = 2;
      } else if (type == "reserve") {
        conText = "设为预定";
        paramStatus = 3;
      } else if (type == "noTalkAbout") {
        conText = "取消在谈";
        paramStatus = 4;
      } else if (type == "noReserve") {
        conText = "取消预定";
        paramStatus = 4;
      } else if (type == "rentable") {
        conText = "设为可租";
        paramStatus = 4;
      }

      this.$confirm(`您确定${conText}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          editHomeStatus({
            roomId: row.roomId,
            houseStatus: paramStatus,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功！");
              this.getTableList();
            } else {
              this.$message.warning("修改失败！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    getTableList() {
      this.loadingFlag = true;
      queryRoomListByParam(this.requestParams)
        .then((res) => {
          res.rows.map((item) => {
            switch (item.houseStatus) {
              case 0:
                item.houseStatus = "空置";
                break;
              case 1:
                item.houseStatus = "已租";
                break;
              case 2:
                item.houseStatus = "在谈";
                break;
              case 3:
                item.houseStatus = "预定";
                break;
              case 4:
                item.houseStatus = "可租";
                break;
              default:
                break;
            }
            const isTalkStatus = item.houseStatus == "可租" ? true : false;
            this.$set(item, "isTalk", isTalkStatus); //设为在谈

            const isReserveStatus = item.houseStatus == "可租" ||  item.houseStatus == "在谈" ? true : false;
            this.$set(item, "isReserve", isReserveStatus); //设为预定

            const noTalkAbout = item.houseStatus == "在谈" ? true : false;
            this.$set(item, "isnoTalkAbout", noTalkAbout); // 取消在谈

            const noFlagStatus = item.houseStatus == "预定" ? true : false;
            this.$set(item, "noFlagRes", noFlagStatus); //取消预定

            const isRentable = item.houseStatus == "空置" ? true : false;
            this.$set(item, "isRentable", isRentable); //可租
            
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    getTabStoreList() {
      getStoreList().then((res) => {
        if (res.length > 0) {
          this.dataset.searchLineConfig.storeName.optionList = res.map(
            (item) => {
              return {
                value: item.storeId,
                label: item.storeName,
              };
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.payment-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
    }

    .icon-loudoutu {
      font-size: 12px;
    }

    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }

    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }

  .link-span {
    cursor: pointer;
    color: #409eff;
  }
}
</style>